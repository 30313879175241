import { parser } from "mathjs";
import React from "react";

export class AInput extends React.Component {

    renderInput() {
        return (<></>);
    }

    render() {
        return this.renderInput();
    }

    randomize() {

    }

    validate() {
        return true;
    }

    generateRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    setBuiltInMathParserFunctions(parser) {}

    getMathParser(scope) {
        scope = Object.assign(scope || {}, this.props.variables);
        const p = parser();
        this.setBuiltInMathParserFunctions(p);
        for (const identifier in scope) {
            p.set(identifier, scope[identifier]);
        }
        const initialScopeExpressions = this.state.data.initialScope || [];
        for (const expr of initialScopeExpressions) {
            p.evaluate(expr);
        }
        return p;
    }
}