import React from 'react';
import styles from './inputs.module.scss';
import { AInput } from './AInput';
import _ from 'lodash';

const randomizeUntilNotValue = (options, notValue) => {
  let randomValue = _.sample(options);
  while(randomValue === notValue){
    randomValue = _.sample(options);
  }
  return randomValue;
}

export class MyDropdownInput extends AInput {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }

  setBuiltInMathParserFunctions(parser) {
    parser.set("randomizeUntilNotValue", randomizeUntilNotValue);
  }

  randomizeDropdown() {
    const rndRules = this.state.data.randomizeValues;
    if (!rndRules) {
      return;
    }
    const scope = Object.assign({}, {});
    const parser = this.getMathParser(scope);
    const value = parser.evaluate(rndRules);
    this.onValueChange({ target: { value: value } });
  }

  randomize() {
    if (this.state.data.randomize) {
      const randomValue = _.sample(this.props.data.options);
      this.onValueChange({ target: { value: randomValue } });
    }
    this.randomizeDropdown();
  }

  onValueChange(ev) {
    const data = this.state.data;
    const value = ev.target.value;
    data.value = value;
    this.setState({data}, () => {
      if (this.props.onUpdate) {
        this.props.onUpdate();
      }
      if (this.props.onValueChange) {
        this.props.onValueChange();
      }
    });
  }

  validate() {
    const validateExpr = this.state.data.validate;
    if (!validateExpr) {
      return true;
    }
    try {
      const data = this.state.data;
      const scope = Object.assign({}, { val: data.value });
      const parser = this.getMathParser(scope);
      return parser.evaluate(validateExpr, scope);
    } catch (error) {
      return false;
    }
  }

  renderInput() {
    return (
      <div className={styles.myDropdownInput}>
        <select
          name="options"
          value={this.state.data.value}
          onChange={this.onValueChange.bind(this)}
          className={this.validate() ? '' : styles.error}
        >
          {this.props.data.options.map((x, i) =>
            <option key={i} value={x}>{x}</option>
          )}
        </select>
      </div>
    );
  }
}