import React from 'react';
import { classes } from 'common/util';
import { Divider } from 'components';
import styles from './ResizableContainer.module.scss';
import trippleArrow from '../../assets/tripple_arrow.svg';


class ResizableContainer extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state to keep track of the original weights
    this.state = {
      initialWeights: props.weights.slice(),
      windowWidth: window.innerWidth,
      weights: props.weights,
    };
    this.openCodeSection = this.openCodeSection.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    if (this.state.windowWidth <= 834) {
      this.mobileLoadSectionsInit();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
    if (this.state.windowWidth <= 834) {
      this.closeCodeSection();
    }
  };

  handleResize(prevIndex, index, targetElement, clientX, clientY) {
    const { horizontal, visibles, onChangeWeights } = this.props;
    const weights = [...this.props.weights];

    const { left, top } = targetElement.getBoundingClientRect();
    const { offsetWidth, offsetHeight } = targetElement.parentElement;
    const position = horizontal ? clientX - left : clientY - top;
    const containerSize = horizontal ? offsetWidth : offsetHeight;

    let totalWeight = 0;
    let subtotalWeight = 0;
    weights.forEach((weight, i) => {
      if (visibles && !visibles[i]) return;
      totalWeight += weight;
      if (i < index) subtotalWeight += weight;
    });
    const newWeight = position / containerSize * totalWeight;
    let deltaWeight = newWeight - subtotalWeight;
    deltaWeight = Math.max(deltaWeight, -weights[prevIndex]);
    deltaWeight = Math.min(deltaWeight, weights[index]);
    weights[prevIndex] += deltaWeight;
    weights[index] -= deltaWeight;
    onChangeWeights(weights);
  }

  openCodeSection() {
    if (this.state.windowWidth <= 834) {
      const newWeights = [this.props.weights[0], 0, 4];
      this.props.handleChangeWorkspaceWeights(newWeights);
    } else {
      const newWeights = [this.props.weights[0], 2, 2];
      this.props.handleChangeWorkspaceWeights(newWeights);
    }
  }

  closeCodeSection() {
    const newWeights = [this.props.weights[0], 4, 0];
    this.props.handleChangeWorkspaceWeights(newWeights);
  }
  mobileLoadSectionsInit() {
    const newWeights = [0, 4, 0];
    this.props.handleChangeWorkspaceWeightsInitial(newWeights);
    if (this.props.isMenuOpen){
      this.props.handleMenuToggle();
    }
  }

  render() {
    const { className, children, horizontal, weights, visibles } = this.props;
    const { editingFile } = this.props.current;

    let modifiedChildren = children;
    let modifiedWeights = weights;
    let isRenderOpenCodeSectionCtrl = true;
    // TODO: refactor this hack and configure this behavior via the routing
    if (typeof editingFile !== "undefined" && editingFile.name === 'Startseite.md') {
      modifiedChildren = children.slice(0, -1);
      modifiedWeights = weights.slice(0, -1);
      modifiedWeights[1] = weights[1] + weights[2]
      isRenderOpenCodeSectionCtrl = false;
    }

    const elements = [];
    let lastIndex = -1;
    const totalWeight = weights.filter((weight, i) => !visibles || visibles[i])
      .reduce((sumWeight, weight) => sumWeight + weight, 0);
    modifiedChildren.forEach((child, i) => {
      if (!visibles || visibles[i]) {
        if (~lastIndex) {
          const prevIndex = lastIndex;
          elements.push(
            <Divider key={`divider-${i}`} horizontal={horizontal}
                     onResize={((target, dx, dy) => this.handleResize(prevIndex, i, target, dx, dy))} />,
          );
        }
        elements.push(
          <div key={i} className={classes(styles.wrapper)} style={{
            flexGrow: modifiedWeights[i] / totalWeight,
          }}>
            {child}
          </div>,
        );
        lastIndex = i;
      }
    });

    return (
      <div className={classes(styles.resizable_container, horizontal && styles.horizontal, className)}>
        {elements}
        {!isRenderOpenCodeSectionCtrl ? <></> : (weights[2] === 0 && !this.props.isMenuOpen) && (
          <div className={styles.button_container} onClick={this.openCodeSection}>
            <img src={trippleArrow} alt='tripple arrow' />
            <div className={styles.title}>{editingFile && (editingFile.name + (this.props.isMenuOpen ? ' (Menu Open)' : ''))}</div>
          </div>
        )}

        {!isRenderOpenCodeSectionCtrl ? <></> : (weights[2] === 0 && window.innerWidth > 834) && (
          <div className={styles.button_container} onClick={this.openCodeSection}>
            <img src={trippleArrow} alt='tripple arrow' />
            <div className={styles.title}>{editingFile && (editingFile.name)}</div>
          </div>
        )}
      </div>
    );
    
  }
}

export default ResizableContainer;
