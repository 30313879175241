import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AutosizeInput from 'react-input-autosize';
import screenfull from 'screenfull';
import Promise from 'bluebird';
import Logo from '../../assets/HSU_Logo.svg';
import faShare from '@fortawesome/fontawesome-free-solid/faShareAlt';
import faMenu from '@fortawesome/fontawesome-free-solid/faBars';
import faClose from '@fortawesome/fontawesome-free-solid/faTimes';
import { GitHubApi } from 'apis';
import { classes, refineGist } from 'common/util';
import { actions } from 'reducers';
import { languages } from 'common/config';
import { BaseComponent, Button, Ellipsis, ListItem, Player } from 'components';
import styles from './Header.module.scss';
import Swal from 'sweetalert2';
import { faEllipsisV } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class Header extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLernbereich: props.location.pathname === "/",
      isIntroduction: props.location.pathname === "/ueber"
    };
    this.toggleMenuSection = this.toggleMenuSection.bind(this);
    this.toggleNavMenu = this.toggleNavMenu.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (window.innerWidth <= 834) {
        this.toggleMenuSection();
      }
    }
  }

  handleClickFullScreen() {
    if (screenfull.enabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request();
      }
    }
  }

  toggleMenuSection() {
    if (this.props.isMenuOpen) {
      const newWeights = [0, this.props.weights[0] + this.props.weights[1], this.props.weights[2]];
      this.props.handleChangeWorkspaceWeights(newWeights);
      this.props.handleMenuToggle();
    } else {
      const newWeights = [1, 0, 0];
      // const newWeights = [0, this.props.weights[1] + this.props.weights[0], this.props.weights[2]];
      this.props.handleChangeWorkspaceWeights(newWeights);
      this.props.handleMenuToggle();
    }
  }

  toggleNavMenu() {
    if (this.props.isNavMenuOpen) {
      //close nav menu
      this.props.handleNavMenuToggle();
    } else {
      //open nav menu 
      this.props.handleNavMenuToggle();
    }
  }

  handleChangeTitle(e) {
    const { value } = e.target;
    this.props.modifyTitle(value);
  }

  saveGist() {
    const { user } = this.props.env;
    const { scratchPaper, titles, files, lastFiles, editingFile } = this.props.current;
    const gist = {
      description: titles[titles.length - 1],
      files: {},
    };
    files.forEach(file => {
      gist.files[file.name] = {
        content: file.content,
      };
    });
    lastFiles.forEach(lastFile => {
      if (!(lastFile.name in gist.files)) {
        gist.files[lastFile.name] = null;
      }
    });
    gist.files['algorithm-visualizer'] = {
      content: 'https://algorithm-visualizer.org/',
    };
    const save = gist => {
      if (!user) return Promise.reject(new Error('Sign In Required'));
      if (scratchPaper && scratchPaper.login) {
        if (scratchPaper.login === user.login) {
          return GitHubApi.editGist(scratchPaper.gistId, gist);
        } else {
          return GitHubApi.forkGist(scratchPaper.gistId).then(forkedGist => GitHubApi.editGist(forkedGist.id, gist));
        }
      }
      return GitHubApi.createGist(gist);
    };
    save(gist)
      .then(refineGist)
      .then(newScratchPaper => {
        this.props.setScratchPaper(newScratchPaper);
        this.props.setEditingFile(newScratchPaper.files.find(file => file.name === editingFile.name));
        if (!(scratchPaper && scratchPaper.gistId === newScratchPaper.gistId)) {
          this.props.history.push(`/scratch-paper/${newScratchPaper.gistId}`);
        }
      })
      .then(this.props.loadScratchPapers)
      .catch(this.handleError);
  }

  hasPermission() {
    const { scratchPaper } = this.props.current;
    const { user } = this.props.env;
    if (!scratchPaper) return false;
    if (scratchPaper.gistId !== 'new') {
      if (!user) return false;
      if (scratchPaper.login !== user.login) return false;
    }
    return true;
  }

  deleteGist() {
    const { scratchPaper } = this.props.current;
    const { gistId } = scratchPaper;
    if (gistId === 'new') {
      this.props.ignoreHistoryBlock(() => this.props.history.push('/'));
    } else {
      GitHubApi.deleteGist(gistId)
        .then(() => {
          this.props.ignoreHistoryBlock(() => this.props.history.push('/'));
        })
        .then(this.props.loadScratchPapers)
        .catch(this.handleError);
    }
  }

  saveToClipboard() {
    const currentLink = window.location.href;
    navigator.clipboard.writeText(currentLink).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Link wurde in die Zwischenablage kopiert.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
    }).catch((error) => {
      console.error('Kopieren fehlgeschlagen:', error);
    });
  }

  renderNavLinks(onClick) {
    return (<>
      <Link to='/Allgemeines/Startseite' className={classes(styles.nav, this.state.isLernbereich && styles.active)} onClick={onClick}>Lernbereich</Link>
      <Link to='/ueber' className={classes(styles.nav, this.state.isIntroduction && styles.active)} onClick={onClick}>Über die Plattform</Link>
    </>
    );
  }

  render() {
    const { className, isShowPlayer: isShowToolbar } = this.props;
    return (
      <header className={classes(styles.header, className)}>
        <div className={`${styles.row} ${styles.top}`}>
          <div className={`${styles.section} ${styles.navTop}`}>
            <div className={`${styles.logo}`}>
              <a href='https://www.hsu-hh.de/' className={classes(styles.nav)}>
                <img src={Logo} alt='HSU Logo Bild'></img>
              </a>
            </div>
            <div className={`${styles.navContainer}`}>
              {this.renderNavLinks(() => { })}
            </div>
            <Button className={styles.mobileHeaderMenu} icon={this.props.isNavMenuOpen ? faClose : faMenu} primary onClick={this.toggleNavMenu}></Button>
          </div>
        </div>
        <div className={`${styles.row} ${styles.red} ${styles.border} ${styles.toolbar}`}>
          <div className={`${styles.navContainerMobile} ${this.props.isNavMenuOpen ? styles.open : ''}`}>
            {this.renderNavLinks(this.toggleNavMenu.bind(this))}
          </div>

          {
            isShowToolbar || this.props.isNavMenuOpen ?
              (
                <>
                  <div className={styles.section}>
                    <Button className={classes(styles.mobileMenu, styles.desktopView)} icon={this.props.isMenuOpen ? faClose : faMenu} primary onClick={this.toggleMenuSection}></Button>
                    <Button className={classes(styles.mobileMenu, styles.mobileView)} icon={this.props.isMenuOpen ? faClose : faEllipsisV} primary onClick={this.toggleMenuSection}></Button>
                  </div>
                  <div className={`${styles.row} ${styles.red} ${styles.player_container}`}>
                    <Player className={styles.section} />
                    <Button className={styles.share} icon={faShare} primary onClick={this.saveToClipboard}>Share</Button>
                  </div>
                </>
              )
              :
              (<></>)
          }
        </div>
      </header>
    );
  }
}

export default withRouter(
  connect(({ current, env }) => ({ current, env }), actions)(
    Header,
  ),
);

