import React from 'react';
import { connect } from 'react-redux';
import AutosizeInput from 'react-input-autosize';
import { classes } from 'common/util';
import { actions } from 'reducers';
import { languages } from 'common/config';
import styles from './TabContainer.module.scss';
import trippleArrow from '../../assets/tripple_arrow.svg';

class TabContainer extends React.Component {
  constructor(props) {
    super(props);
    this.closeCodeSection = this.closeCodeSection.bind(this);
  }

  closeCodeSection() {
    const newWeights = [this.props.weights[0], 4, 0];
    this.props.handleChangeWorkspaceWeights(newWeights);
  }
  handleAddFile() {
    const { ext } = this.props.env;
    const { files } = this.props.current;
    const language = languages.find(language => language.ext === ext);
    const newFile = { ...language.skeleton };
    let count = 0;
    while (files.some(file => file.name === newFile.name)) newFile.name = `code-${++count}.${ext}`;
    this.props.addFile(newFile);
  }

  render() {
    const { className, children } = this.props;
    const { editingFile, files } = this.props.current;

    const viewableFiles = files.filter(file => file.name.endsWith('.js') || file.name.endsWith("_pseudo.md"));

    return (
      <div className={classes(styles.tab_container, className)}>
        <div className={styles.tab_bar}>
          <div className={`${styles.row} ${styles.hover}`} onClick={this.closeCodeSection}>
            <img src={trippleArrow} alt='tripple arrow'></img>
          </div>
          {
            viewableFiles.map((file, i) => file === editingFile ? (
              <div key={i} className={`${styles.row} ${styles.hover}`}>
                <div className={classes(styles.title, styles.selected)}
                    onClick={() => this.props.setEditingFile(file, false)}>
                  <AutosizeInput className={styles.input_title} value={file.name}
                                onClick={e => e.stopPropagation()}
                                onChange={e => this.props.renameFile(file, e.target.value)}/>
                </div>
              </div>
            ) : (
              <div className={styles.title} key={i} onClick={() => this.props.setEditingFile(file, false)}>
                {file.name}
              </div>
            ))
          }
          <div className={classes(styles.title, styles.fake)}/>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    );
  }
}

export default connect(({ current, env }) => ({ current, env }), actions)(
  TabContainer,
);
