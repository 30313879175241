import React from 'react';
import styles from './inputs.module.scss';
import { AInput } from './AInput';
import _ from 'lodash';

export class MyNumberInput extends AInput {
  constructor(props) {
    super(props);
    this.state = {
        data: this.props.data
    };
  }

  min() {
    const expr = this.state.data.min;
    if (!expr || typeof(expr) === 'number') {
      return expr;
    }
    const p = this.getMathParser();
    return p.evaluate(expr);
  }

  max() {
    const expr = this.state.data.max;
    if (!expr || typeof(expr) === 'number') {
      return expr;
    }
    const p = this.getMathParser();
    return p.evaluate(expr);
  }

  randomize() {
    if (!this.state.data.randomize) {
      return;
    }
    const expr = this.state.data.randomize;
    let val = 0;
    if (typeof(expr) === 'boolean') {
      val = this.generateRandomNumber(this.min(), this.max()) 
    } else {
      const p = this.getMathParser();
      val = p.evaluate(this.state.data.randomize);
    }
    const data = this.state.data;
    data.value = _.clamp(val, this.min(), this.max());
    this.setState({data}, () => {
      if (this.props.onUpdate) {
        this.props.onUpdate();
      }
    });
  }

  onValueChange(ev, x) {
    const data = this.state.data;
    let v = Number.parseFloat(ev.target.value);
    if (Number.isNaN(v)) {
      v = 0;
    }
    data.value = _.clamp(v, this.min(), this.max());
    this.setState({data}, () => {
      if (this.props.onUpdate) {
        this.props.onUpdate();
      }
    });
  }

  renderInput() {
    const min = this.min();
    const max = this.max();
    return (
      <div className={styles.myNumberInput}>
       <input type="number" min={min} max={max} value={this.state.data.value} onChange={this.onValueChange.bind(this)}></input>
      </div>
    );
  }
}

