import React, { createRef } from 'react';
import styles from './Renderer.module.scss';
import { Ellipsis, Button } from 'components';
import { classes } from 'common/util';
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus';
import faMinus from '@fortawesome/fontawesome-free-solid/faMinus';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash';


const positionStore = {
  url: {
    views: {}
  }
}

class Renderer extends React.Component {
  constructor(props) {
    super(props);
    this.parentContainer = createRef();
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

    this._handleMouseDown = this.handleMouseDown;
    this._handleWheel = this.handleWheel;
    this.togglePan(false);
    this.toggleZoom(false);

    this.lastX = null;
    this.lastY = null;
    this.centerX = 0;
    this.centerY = 0;
    this.zoom = 1;
    this.zoomFactor = 1.01;
    this.zoomButtonsVisisble = false;
    this.zoomSteps = [1 / 16, 1 / 8, 1 / 4, 1 / 2, 0.8, 1, 1.2, 1.5, 2, 4, 8, 16, 20];
    this.zoomMax = _.last(this.zoomSteps);
    this.zoomMin = _.first(this.zoomSteps);
    this.currentZoomStep = this.zoomSteps.indexOf(1);
    this.initalWidth = 0;
    this.initialHeight = 0;
    this.url = window.location.href;
    this.state = {
      isUnfolded: true
    }
  }

  initZoomAndPosition() {
    // meant to be overriden
  }

  componentDidMount() {
    const parent = this.parentContainer.current;
    this.initalWidth = parent.clientWidth;
    this.initalHeight = parent.clientHeight;
    this.restoreCurrentPosition();
  }

  componentWillUnmount() {
    this.storeCurrentPosition();
  }

  storeCurrentPosition() {
    const viewKey = this.props.title;
    const currentUrl = this.url;
    const views = positionStore[currentUrl] || {};
    views[viewKey] = {
      centerX: this.centerX,
      centerY: this.centerY,
      zoom: this.zoom,
      currentZoomStep: this.currentZoomStep
    };
    positionStore[currentUrl] = views;
  }

  restoreCurrentPosition() {
    const currentUrl = this.url;
    const views = positionStore[currentUrl];
    if (!views) {
      this.initZoomAndPosition();
      return;
    }
    const viewKey = this.props.title;
    const view = views[viewKey];
    if (!view) {
      this.initZoomAndPosition();
      return;
    }
    this.centerX = view.centerX;
    this.centerY = view.centerY;
    this.zoom = view.zoom;
    this.currentZoomStep = view.currentZoomStep;
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  togglePan(enable = !this.handleMouseDown) {
    this.handleMouseDown = enable ? this._handleMouseDown : undefined;
  }

  toggleZoom(enable) {
    this.zoomButtonsVisisble = enable;
  }

  handleMouseDown(e) {
    const { clientX, clientY } = e;
    this.lastX = clientX;
    this.lastY = clientY;
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove(e) {
    const { clientX, clientY } = e;
    const dx = clientX - this.lastX;
    const dy = clientY - this.lastY;
    this.centerX -= dx;
    this.centerY -= dy;
    this.refresh();
    this.lastX = clientX;
    this.lastY = clientY;
  }

  handleMouseUp(e) {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  toString(value) {
    switch (typeof (value)) {
      case 'number':
        return [Number.POSITIVE_INFINITY, Number.MAX_SAFE_INTEGER, 0x7fffffff].includes(value) ? '∞' :
          [Number.NEGATIVE_INFINITY, Number.MIN_SAFE_INTEGER, -0x80000000].includes(value) ? '-∞' :
            value;
      case 'boolean':
        return value ? 'T' : 'F';
      default:
        return value;
    }
  }

  refresh() {
    this.forceUpdate();
  }

  renderData() {
    return null;
  }

  onZoomMouseEvent(direction) {
    this.currentZoomStep = _.clamp(this.currentZoomStep + direction, 0, this.zoomSteps.length - 1);
    this.zoom = this.zoomSteps[this.currentZoomStep];
    this.refresh();
  }

  onToggleOpenCloseClicked() {
    this.setState({ isUnfolded: !this.state.isUnfolded });
  }

  render() {
    const { className, title } = this.props;

    const zoomButtons = (
      <div className={styles.zoomButtons}>
        <Button className={styles.button} icon={faPlus} primary onClick={this.onZoomMouseEvent.bind(this, 1)}></Button>
        <Button className={styles.button} icon={faMinus} primary onClick={this.onZoomMouseEvent.bind(this, -1)}></Button>
        <div className={styles.zoomText}>
          Zoom {this.zoom}
        </div>
      </div>
    );
    return (
      <div className={classes(styles.renderer, className, this.state.isUnfolded ? '' : styles.rendererCollapsed)} onMouseDown={this.handleMouseDown} ref={this.parentContainer}>
        <div className={classes(styles.infoContainer)} >
          <div className={classes(styles.showHideCtrl)} onClick={this.onToggleOpenCloseClicked.bind(this)}>
            <Ellipsis className={styles.title} >
              <FontAwesomeIcon className={styles.icon} fixedWidth icon={faAngleRight} />
              {title}
            </Ellipsis>
          </div>
        </div>
        {this.zoomButtonsVisisble ? (zoomButtons) : (<></>)}
        {
          this.renderData()
        }
      </div>
    );
  }
}

export default Renderer;

