import React from 'react';
import { Renderer } from 'core/renderers';
import { classes, distance, stringContainsNumber } from 'common/util';
import styles from './GraphRenderer.module.scss';

class GraphRenderer extends Renderer {
  constructor(props) {
    super(props);
    this.graphNodes = props.data.graphNodes;
    this.elementRef = React.createRef();
    this.selectedNode = null;

    this.togglePan(true);
    this.toggleZoom(true);
  }


  isDragEnabled() {
    return this.props.data.dragEnabled;
  }

  handleMouseDown(e) {
    super.handleMouseDown(e);
    if (!this.isDragEnabled()) {
      return;
    }
    const coords = this.computeCoords(e);
    const { nodes, dimensions } = this.props.data;
    const { nodeRadius } = dimensions;
    this.selectedNode = nodes.find(node => distance(coords, node) <= nodeRadius);
  }

  handleMouseMove(e) {
    if (this.selectedNode) {
      const { x, y } = this.computeCoords(e);
      const node = this.props.data.findNode(this.selectedNode.id);
      node.x = x;
      node.y = y;
      this.refresh();
    } else {
      super.handleMouseMove(e);
    }
  }

  computeCoords(e) {
    const svg = this.elementRef.current;
    const s = svg.createSVGPoint();
    s.x = e.clientX;
    s.y = e.clientY;
    const { x, y } = s.matrixTransform(svg.getScreenCTM().inverse());
    return { x, y };
  }

  getLabel(id) {
    if (!this.props.data.graphNodeLabels) {
      return id;
    }
    const lbl = this.props.data.graphNodeLabels[id];
    if (lbl) {
      if (typeof (lbl) === 'string') {
        return this.props.data.graphNodeLabels[id];
      }
      return lbl.label;
    }
    return id;
  }

  getSubLabel(id) {
    if (!this.props.data.graphNodeLabels) {
      return "";
    }
    const lbl = this.props.data.graphNodeLabels[id];
    if (typeof (lbl) !== 'object') {
      return;
    }
    return lbl.sublabel;
  }

  renderData() {
    const { nodes, edges, isDirected, isWeighted, dimensions} = this.props.data;
    const { baseWidth, baseHeight, nodeRadius, arrowGap, nodeWeightGap, edgeWeightGap } = dimensions;
    const viewOptions =  this.props.data.viewOptions || {};
    const auxElements = this.props.data.auxElements || [];
    let shiftX = viewOptions.shiftX || 300;
    let shiftY = viewOptions.shiftY || 300;
    let shiftZoom = viewOptions.shiftZoom || 0;
    const viewBox = [
      (this.centerX - baseWidth + shiftX / 2) / this.zoom,
      (this.centerY - baseHeight + shiftY / 2) / this.zoom,
      baseWidth / this.zoom + shiftZoom,
      baseHeight / this.zoom + shiftZoom
    ];
    return (
      <svg className={styles.graph} viewBox={viewBox} ref={this.elementRef}>
        <defs>
          <filter x="0" y="0" width="1" height="1" id="solid">
            <feFlood floodColor="white" result="bg" />
            <feMerge>
              <feMergeNode in="bg" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <marker id="markerArrow" markerWidth="4" markerHeight="4" refX="2" refY="2" orient="auto">
            <path d="M0,0 L0,4 L4,2 L0,0" className={styles.arrow} />
          </marker>
          <marker id="markerArrowSelected" markerWidth="4" markerHeight="4" refX="2" refY="2" orient="auto">
            <path d="M0,0 L0,4 L4,2 L0,0" className={classes(styles.arrow, styles.selected)} />
          </marker>
          <marker id="markerArrowVisited" markerWidth="4" markerHeight="4" refX="2" refY="2" orient="auto">
            <path d="M0,0 L0,4 L4,2 L0,0" className={classes(styles.arrow, styles.visited)} />
          </marker>
          <marker id="markerArrowFlow" markerWidth="4" markerHeight="4" refX="2" refY="2" orient="auto">
            <path d="M4,0 L4,4 L0,2 L4,0" className={classes(styles.arrow)} />
          </marker>
          <marker id="markerArrowFlowSelected" markerWidth="4" markerHeight="4" refX="2" refY="2" orient="auto">
            <path d="M4,0 L4,4 L0,2 L4,0" className={classes(styles.arrow, styles.red)} />
          </marker>
        </defs>

        {auxElements.map((el, index) => {
          switch (el.type) {
            case 'path': return (<path key={index} d={el.d} style={el.style}></path>);
            case 'text': return (<text key={index} x={el.x} y={el.y} style={el.style}>{el.text}</text>);
            default: return (<></>);
          }
        })}

        {
          edges.sort((a, b) => a.visitedCount - b.visitedCount).map(edge => {
            const { source, target, weight, highlightFlow, flow, visitedCount, selectedCount, styling, parallelNr, customEdgeId } = edge;
            const sourceNode = this.props.data.findNode(source);
            const targetNode = this.props.data.findNode(target);
            if (!sourceNode || !targetNode) return undefined;
            const { x: sx, y: sy } = sourceNode;
            let { x: ex, y: ey } = targetNode;

            const ox = ex;
            const oy = ey;

            const mx = (sx + ex) / 2;
            const my = (sy + ey) / 2;
            const dx = ex - sx;
            const dy = ey - sy;
            let fx, fy;
            const degree = Math.atan2(dy, dx) / Math.PI * 180;
            if (isDirected) {
              const length = Math.sqrt(dx * dx + dy * dy);
              if (length !== 0) {
                fx = ex - dx / length * (length - nodeRadius - arrowGap);
                fy = ey - dy / length * (length - nodeRadius - arrowGap);
                ex = sx + dx / length * (length - nodeRadius - arrowGap);
                ey = sy + dy / length * (length - nodeRadius - arrowGap);
              }
            }

            const calculateYTransform = (degree) => {
              return 3 * Math.cos(degree * (Math.PI / 180));
            };
            const color = (styling && styling.color) && visitedCount ? styling.color : '';
            const strokeWidth = (styling && styling.strokeWidth) && visitedCount ? styling.strokeWidth : '';
            let edgePathData = `M${sx},${sy} L${ex},${ey}`;
            let weightX = mx;
            let weightY = my;
            if (parallelNr) {
              const distance = Math.sqrt(Math.pow(sx - ex, 2) + Math.pow(sy - ey, 2));
              let sagittaCurve  = 50/distance * Math.ceil(edge.parallelNr / 2) * (edge.parallelNr % 2 === 0 ? -1 : 1);
              if (target > source) {
                sagittaCurve *= -1;
              }
              const sagittaWeight = sagittaCurve/2; 
              const s = { x: -sagittaCurve  * (ey - sy) + mx, y: sagittaCurve  * (ex - sx) + my };
              edgePathData = `M${sx} ${sy} Q ${s.x} ${s.y} ${ex} ${ey}`;
              weightX = -sagittaWeight * (ey - sy) + mx;
              weightY = sagittaWeight  * (ex - sx) + my;
            }
            return (
              <g className={classes(styles.edge, selectedCount && styles.selected, visitedCount && styles.visited)}
                key={`${source}-${target}-${parallelNr}`}>

                <path d={edgePathData} style={{stroke: color, strokeWidth: strokeWidth, fill: "none"}} transform={flow ? `translate(${3 / 90 * degree},${-calculateYTransform(degree)})` : undefined} className={classes(styles.line, isDirected && styles.directed)} />
                {flow &&
                  <path d={`M${fx},${fy} L${ox},${oy}`} style={{stroke: color, strokeWidth: strokeWidth}} transform={`translate(${-3 / 90 * degree},${calculateYTransform(degree)})`} className={classes(styles.line, isDirected && styles.directed, flow && styles.flow, highlightFlow && styles.flowSelected)} />
                }
                {
                  isWeighted && // WEIGHT
                  <g transform={`translate(${weightX},${weightY})`}>
                    <text className={classes(styles.weight)} style={{fill: color}} transform={flow ? `rotate(${degree}), translate(${0},${-5})` : `rotate(${degree})`}
                      y={-edgeWeightGap}>{this.toString(weight)}{typeof(weight) === 'string' ? '' : '.'}</text>
                  </g>
                }
                {
                  isWeighted && flow && // FLOW
                  <g transform={`translate(${mx},${my})`}>
                    <text className={classes(styles.weight, flow && styles.flow, highlightFlow && styles.flowSelected)} style={{fill: color}} transform={`rotate(${degree}), translate(${0},${20})`}
                      y={-edgeWeightGap}>{this.toString(flow)}.</text>
                  </g>
                }
              </g>
            );
          })
        }
        {
          nodes.map(node => {
            const { id, x, y, weight, visitedCount, selectedCount, styling } = node;
            const color = (styling && styling.color) && visitedCount ? styling.color : '';
            const bgColor = (styling && styling.background) && visitedCount ? styling.background : '';
            return (
              <g className={classes(styles.node, selectedCount && styles.selected, visitedCount && styles.visited)}
                key={id} transform={`translate(${x},${y})`}>
                <circle className={styles.circle} style={{fill: bgColor, stroke: bgColor}} r={nodeRadius} />
                <text className={styles.label} style={{fill: color}}>{this.getLabel(id)}</text>
                <text y="18" filter="url(#solid)" className={styles.subLabel}>{this.getSubLabel(id)}</text>
                {
                  isWeighted &&
                  <text className={styles.weight} x={nodeRadius + nodeWeightGap}>{this.toString(weight)}</text>
                }
              </g>
            );
          })
        }
      </svg>
    );
  }
}

export default GraphRenderer;

