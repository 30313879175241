const classes = (...arr) => arr.filter(v => v).join(' ');

const distance = (a, b) => {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return Math.sqrt(dx * dx + dy * dy);
};

const extension = fileName => /(?:\.([^.]+))?$/.exec(fileName)[1];

const refineGist = gist => {
  const gistId = gist.id;
  const title = gist.description;
  delete gist.files['algorithm-visualizer'];
  const { login, avatar_url } = gist.owner;
  const files = Object.values(gist.files).map(file => ({
    name: file.filename,
    content: file.content,
    contributors: [{ login, avatar_url }],
  }));
  return { login, gistId, title, files };
};

const createFile = (name, content, contributors) => ({ name, content, contributors });

const createProjectFile = (name, content) => createFile(name, content, [{
  login: 'algorithm-visualizer',
  avatar_url: 'https://github.com/algorithm-visualizer.png',
}]);

const createUserFile = (name, content) => createFile(name, content, undefined);

const isSaved = ({ titles, files, lastTitles, lastFiles }) => {
  const serialize = (titles, files) => JSON.stringify({
    titles,
    files: files.map(({ name, content }) => ({ name, content })),
  });
  return serialize(titles, files) === serialize(lastTitles, lastFiles);
};

const stringContainsNumber = (str) => {
  // const stringContainsNumber = (str) => {
  //   if (!str || !str.trim()) return false;
  //   return str.match(/^\s*-{0,1}\d*\.{0,1}\d+\s*$/);
  // }
  
  // function assert(val) {
  //     if (!val) {
  //         throw new Error("")
  //     }
  // }
  
  // assert(!stringContainsNumber(""))
  // assert(!stringContainsNumber(" "))
  // assert(stringContainsNumber("0"))
  // assert(stringContainsNumber("0.12345"))
  // assert(stringContainsNumber(".12345"))
  // assert(!stringContainsNumber("0,1"))
  // assert(!stringContainsNumber(",1"))
  // assert(!stringContainsNumber("1a"))
  // assert(stringContainsNumber("-0"))
  // assert(stringContainsNumber("-1"))
  // assert(stringContainsNumber("-1.2"))
  // assert(stringContainsNumber("-.12345"))
  // assert(!stringContainsNumber("-"))
  // assert(!stringContainsNumber("."))
  // assert(!stringContainsNumber("--2"))
  // assert(!stringContainsNumber("1..2"))
  // console.log("SUCCESS")

  if (!str || !str.trim()) return false;
  return str.match(/^\s*-{0,1}\d*\.{0,1}\d+\s*$/);
}


export {
  classes,
  distance,
  extension,
  refineGist,
  createFile,
  createProjectFile,
  createUserFile,
  isSaved,
  stringContainsNumber
};
