import MyPlotterRenderer from 'core/renderers/MyPlotterRenderer';
import { Tracer } from 'core/tracers';

class MyPlotterTracer extends Tracer {
  getRendererClass() {
    return MyPlotterRenderer;
  }

  set(variables) {
    if (!variables) {
      return;
    }
    if (variables.opts) {
      this.opts = variables.opts;
    } else {
      this.data = variables;
    }
    super.set();
  }
}

export default MyPlotterTracer;
