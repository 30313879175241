import Promise from 'bluebird';
import axios from 'axios';

axios.interceptors.response.use(response => response.data);

const request = (url, process) => {
  const tokens = url.split('/');
  return (...args) => {
    const mappedURL = tokens.map((token, i) => token.startsWith(':') ? args.shift() : token).join('/');
    return Promise.resolve(process(mappedURL, args));
  };
};

const GET = URL => {
  return request(URL, (mappedURL, args) => {
    const [params, cancelToken] = args;
    return axios.get(mappedURL, { params, cancelToken });
  });
};

const DELETE = URL => {
  return request(URL, (mappedURL, args) => {
    const [params, cancelToken] = args;
    return axios.delete(mappedURL, { params, cancelToken });
  });
};

const POST = URL => {
  return request(URL, (mappedURL, args) => {
    const [body, params, cancelToken] = args;
    return axios.post(mappedURL, body, { params, cancelToken });
  });
};

const PUT = URL => {
  return request(URL, (mappedURL, args) => {
    const [body, params, cancelToken] = args;
    return axios.put(mappedURL, body, { params, cancelToken });
  });
};

const PATCH = URL => {
  return request(URL, (mappedURL, args) => {
    const [body, params, cancelToken] = args;
    return axios.patch(mappedURL, body, { params, cancelToken });
  });
};

const AlgorithmApi = {
  // TODO HSU
  getCategories: GET('/algorithms'),
  getAlgorithm: GET('/algorithms/:categoryKey/:algorithmKey'),
};

const VisualizationApi = {
  getVisualization: GET('/visualizations/:visualizationId'),
};

const GitHubApi = {
  auth: token => Promise.resolve(axios.defaults.headers.common['Authorization'] = token && `token ${token}`),
  getUser: GET('https://api.github.com/user'),
  listGists: GET('https://api.github.com/gists'),
  createGist: POST('https://api.github.com/gists'),
  editGist: PATCH('https://api.github.com/gists/:id'),
  getGist: GET('https://api.github.com/gists/:id'),
  deleteGist: DELETE('https://api.github.com/gists/:id'),
  forkGist: POST('https://api.github.com/gists/:id/forks'),
};

export let MyCurrentJsData = {
};

export let initialLoading = true;

const TracerApi = {
  md: ({ code }) => Promise.resolve(),
  json: ({ code }) => new Promise(resolve => resolve(JSON.parse(code))),
  js: ({ code }, params, cancelToken) => new Promise((resolve, reject) => {
    const worker = new Worker('/api/tracers/js/worker.js');
    if (cancelToken) {
      cancelToken.promise.then(cancel => {
        worker.terminate();
        reject(cancel);
      });
    }
    worker.onmessage = e => {
      const {commands, valueStorage} = e.data;
      const isValueStorageEmpty = Object.keys(valueStorage).length === 0;
      if(!isValueStorageEmpty){
        valueStorage.path = window.location.pathname;
        MyCurrentJsData = valueStorage;
        const encodedData = btoa(JSON.stringify(valueStorage));
        const newUrl = `${window.location.pathname}?d=${encodedData}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
      worker.terminate();
      resolve(commands);
    };
    worker.onerror = error => {
      worker.terminate();
      reject(error);
    };

    const urlParams = new URLSearchParams(window.location.search);
    if(initialLoading && urlParams.get("d") && Object.keys(MyCurrentJsData).length === 0){
      try{
        const jsonData = JSON.parse(atob(urlParams.get("d")));
        MyCurrentJsData = jsonData;
        initialLoading = false;
      }
      catch(error){
        window.history.pushState({ path: window.location.pathname }, '', window.location.pathname)
      }
    }
    if ("path" in MyCurrentJsData && MyCurrentJsData.path !== window.location.pathname) {
      MyCurrentJsData = {};
      window.history.pushState({ path: window.location.pathname }, '', window.location.pathname)
    }
    worker.postMessage({code, myCurrentJsData: MyCurrentJsData});
  }),
  cpp: POST('/tracers/cpp'),
  java: POST('/tracers/java'),
};

export {
  AlgorithmApi,
  VisualizationApi,
  GitHubApi,
  TracerApi,
};
