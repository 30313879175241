import React from 'react';
import { Renderer } from 'core/renderers';
import styles from './MyGanttRenderer.module.scss';


class MyGanttRenderer extends Renderer {
  constructor(props) {
    super(props);
    this.togglePan(true);
    this.toggleZoom(true);
    this.tasks = props.data.tasks || [];
    this.steps = props.data.data.steps || 100;
    this.rowLabel = props.data.data.rowLabel ?? "Maschine 1";
    this.colLabel = props.data.data.colLabel ?? "Zeit";
    this.highlightedTasks = null;
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate() {
    super.componentDidUpdate();
    this.updateTasksIfNecessary();
    this.highlightedTasks = this.props.data.data.highlightedTasks
  }


  updateTasks() {
    this.tasks = this.props.data.data.tasks;
  }

  updateTasksIfNecessary() {
    if (this.props.data.data.tasks === this.tasks) {
      return;
    }
    this.updateTasks();
  }

  generatePaleColor(seed) {
    const componentToHex = c => {
        return c.toString(16).padStart(2, '0');
    }

    const randomComponent = seed => {
        return (Math.abs(Math.sin(seed)) * 56 + 200) | 0;
    }

    let r = randomComponent(seed);
    let g = randomComponent(seed + 1);
    let b = randomComponent(seed + 2);

    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

  renderData() {

    this.moveX = -this.centerX;
    this.moveY = -this.centerY;

    return (<div style={{ transform: `translate(${this.moveX}px, ${this.moveY}px) scale(${this.zoom})` }}>
      <div className={styles.myGantt}>
        <div className={styles.machineLabel}>{this.rowLabel}</div>
        <div className={styles.myGanttContent}>
          <div className={styles.myGanttContentValues} style={{gridTemplateColumns: Array(this.steps).fill('25px').join(' ')}}>
            {
              this.tasks.map((task, index) => { 
                return (
                  <div
                    id={task.id}
                    key={task.id}
                    className={`${styles.gridItem} ${this.highlightedTasks.includes(task.id) ? styles.highlighted : ''}`}
                    style={{ backgroundColor: task.name === 'M' ? '#c8ffc8' : this.generatePaleColor(index), gridColumnStart: task.start + 1, gridColumnEnd: task.end + 1 }}
                    data-name={task.name}
                    data-duration={task.duration}
                    data-start={task.start}
                    data-end={task.end}
                  >
                    <p>
                      {task.name}
                    </p>
                  </div>
                )
              })
            }
          </div>
        <div className={styles.myGanttContentLabels} style={{gridTemplateColumns: Array(this.steps+3).fill('25px').join(' ')}}>
            {
              Array(this.steps+1).fill('').map((_, index) => { 
                return (
                  <p key={`x_${index}`}>
                  {index}
                </p>
                )
              })
            }
            <p key={`x_empty`}></p>
            <p key={`x_label`}>
              {this.colLabel}
            </p>
          </div>
        </div>
        </div>
      </div>);
  }
}

export default MyGanttRenderer;

