import React from 'react';
import styles from './inputs.module.scss';
import { AInput } from './AInput';
import * as _ from 'lodash';
import { matrix } from 'mathjs';
import { stringContainsNumber } from 'common/util';
import ReactHtmlParser from 'react-html-parser'; 


const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const generateNonBipartiteGraphMatrix = (n) => {
  // Leere Matrix n x n
  const matrix = [];
  for (let i = 0; i < n; i++) {
    matrix.push(new Array(n).fill(0));
  }
  // Verbindet jeden Knoten mit min. einem anderen zufaelligen Knoten
  for (let i = 0; i < n; i++) {
    let connected = false;
    for (let j = i + 1; j < n; j++) {
      if (Math.random() < 0.5) {
        matrix[i][j] = 1;
        matrix[j][i] = 1;
        connected = true;
        break;
      }
    }
    // Falls Knoten i nicht verbunden, verbinde mit anderem zufaelligen Knoten
    if (!connected) {
      const randomNode = Math.floor(Math.random() * n);
      matrix[i][randomNode] = 1;
      matrix[randomNode][i] = 1;
    }
  }

  // Sicherstellung: Graph nicht bipartit --> zyklische Verbindung
  let randomNode1 = Math.floor(Math.random() * n);
  let randomNode2 = (randomNode1 + 2) % n; // Knoten mit Abstand 2: zyklische Verbindung
  matrix[randomNode1][randomNode2] = 1;
  matrix[randomNode2][randomNode1] = 1;

  for (let i = 0; i < n; i++) {
    let next = (i + 1) % n;
    matrix[i][next] = 1;
    matrix[next][i] = 1;
  }

  // Diagonale auf Null
  for (let i = 0; i < n; i++) {
    matrix[i][i] = 0;
  }

  return matrix;
}

const generateBipartiteGraphMatrix = (n) => {
  // Leere Matrix n x n
  const matrix = [];
  for (let i = 0; i < n; i++) {
    matrix.push(new Array(n).fill(0));
  }

  // Größe Teilmenge
  const partitionSize = Math.floor(n / 2);

  // Zufällige Verteilung der Knoten
  const nodes = Array.from({ length: n }, (_, i) => i);
  shuffleArray(nodes);

  // Verbindet Knoten der ersten Teilmenge mit allen Knoten der zweiten Teilmenge
  for (let i = 0; i < partitionSize; i++) {
    for (let j = partitionSize; j < n; j++) {
      matrix[nodes[i]][nodes[j]] = 1;
      matrix[nodes[j]][nodes[i]] = 1;
    }
  }
  return matrix;
}

const isListAndInRange = (l, min, max, notAllowed=null) => {
  const list = l.split(",");

  let inRange = notAllowed !== null ? !list.includes(String(notAllowed)) : true;
  for(let v of list){
    inRange = v >= min && v <= max ? inRange : false;
  }

  return Array.isArray(list) && list.length > 0 && inRange;
}

const includes = (list, element) => {
  return list._data.includes(element);
}

const randomExclude = (min, max, excluded) => {
  var n = Math.floor(Math.random() * (max-min) + min);
  if (n >= excluded) n++;
  return n;
}

const completeList = (list) => {
  for(let element of list.split(",")){
    if(element.trim() === "" || Number.isNaN(parseInt(element.trim()))){
      return false;
    }
  }
  return true;
}

const countNonEmptyElements = (list) => {
  return list.filter(x => x !== "" && x !== " ").length;
}


export class MyMatrixInput extends AInput {
  constructor(props) {
    super(props);
    const rows = this.props.data.value;
    this.props.data.nExpr = this.props.data.n;
    this.props.data.mExpr = this.props.data.m;
    this.props.data.n = rows[0].length;
    this.props.data.m = rows.length;
    this.state = {
      data: this.props.data,
    };
  }

  setBuiltInMathParserFunctions(parser) {
    parser.set("generateNonBipartiteGraphMatrix", generateNonBipartiteGraphMatrix);
    parser.set("generateBipartiteGraphMatrix", generateBipartiteGraphMatrix);
    parser.set("isListAndInRange", isListAndInRange);
    parser.set("includes", includes);
    parser.set("randomExclude", randomExclude);
    parser.set("completeList", completeList);
    parser.set("countNonEmptyElements", countNonEmptyElements);
  }

  getValueFromEvent(ev) {
    return ev.target.value;
  }

  setValueData(data) {
    this.setState({ data }, () => {
      if (this.props.onValueChange) {
        this.props.onValueChange();
      }
    });
  }


  updateValue(m, n, value) {
    const data = this.state.data;
    if (typeof(value) === "string" && stringContainsNumber(value)) {
      value = Number.parseFloat(value);
    }
    data.value[m][n] = value;
    return data;
  }

  onValueChange(m, n, ev) {
    let value = this.getValueFromEvent(ev);
    const data = this.updateValue(m, n, value);
    this.setValueData(data);
  }

  randomizeMatrixValues() {
    const rndRules = this.state.data.randomizeValues;
    if (!rndRules) {
      return;
    }
    const n = this.state.data.n;
    const m = this.state.data.m;
    const data = this.state.data;
    const rnd = (m, n) => {
      if (Array.isArray(rndRules)) {
        const min = rndRules[0];
        const max = rndRules[1];
        return this.generateRandomNumber(min, max);
      }
      if (typeof rndRules === 'string') {
        const scope = Object.assign({}, { n, m, mat: data.value });
        const parser = this.getMathParser(scope);
        return parser.evaluate(rndRules);
      }
    }
    for (let i = 0; i < m; ++i) {
      for (let j = 0; j < n; ++j) {
        data.value[i][j] = rnd(i, j);
      }
    }
    this.setState({ data });
  }

  validateCell(m, n, expr) {
    expr = expr || this.state.data.validate;
    if (!expr) {
      return true;
    }
    try {
      const data = this.state.data;
      const val = data.value[m][n];
      const scope = Object.assign({}, { n, m, val, mat: data.value });
      const parser = this.getMathParser(scope);
      return parser.evaluate(expr, scope);
    } catch (error) {
      return false;
    }
  }

  validate() {
    const validateExpr = this.state.data.validate;
    if (!validateExpr) {
      return true;
    }
    const n = this.state.data.n;
    const m = this.state.data.m;
    for (let i = 0; i < m; ++i) {
      for (let j = 0; j < n; ++j) {
        if (!this.validateCell(i, j, validateExpr)) {
          return false;
        }
      }
    }
    return true
  }

  setDefaultValues(i, j){
    const defaultValue = this.state.data.defaultValue;
    if(!defaultValue){
      return;
    }

    const defaultV = (m, n) => {
      if (Array.isArray(defaultValue)) {
        const min = defaultValue[0];
        const max = defaultValue[1];
        return this.generateRandomNumber(min, max);
      }
      if (typeof defaultValue === 'string') {
        const scope = Object.assign({}, { n, m });
        const parser = this.getMathParser(scope);
        return parser.evaluate(defaultValue);
      }
    }
    return defaultV(i, j);
  }

  setDefaultValuesForAll(){
    const n = this.state.data.n;
    const m = this.state.data.m;
    const data = this.state.data;
    for (let i = 0; i < m; ++i) {
      for (let j = 0; j < n; ++j) {
        let value = data.value[i][j];
        const isEmpty = typeof(value) === 'string' && value.trim() === '';
        if((isEmpty || !value) && !this.isMatrixInputDisabled(i, j)){
          data.value[i][j] = this.createInitalValue(i, j);
        }
      }
    }
    this.setState({ data }, () => {
      if (this.props.onUpdate) {
        this.props.onUpdate();
      }
    });
  }

  createInitalValue(i, j) {
    return this.state.data.defaultValue ? this.setDefaultValues(i, j) : "";
  }

  updateBounds(m, n) {
    return new Promise((resolve) => {
      const data = this.state.data;
      const oldRows = data.value;

      const scope = Object.assign({}, { n, m });
      const parser = this.getMathParser(scope);

      const updateBoundsAtIndexCol = this.state.data.updateBoundsAtIndex && this.state.data.updateBoundsAtIndex.col ? parser.evaluate(this.state.data.updateBoundsAtIndex.col, scope) : null;
      const updateBoundsAtIndexRow = this.state.data.updateBoundsAtIndex && this.state.data.updateBoundsAtIndex.row ? parser.evaluate(this.state.data.updateBoundsAtIndex.row, scope) : null;
      
      let newRows = [];
      for (let i = 0; i < m; ++i) {
        const row = [];
        newRows.push(row);
        for (let j = 0; j < n; ++j) {
          row[j] = !this.isMatrixInputDisabled(i, j) ? (oldRows[i] || [])[j] || this.createInitalValue(i, j) : '';
        }
      }

      const newColsLength = newRows[0].length - oldRows[0].length;
      if(updateBoundsAtIndexCol && newColsLength > 0){
        newRows.forEach(x => {
          const newElements = x.splice(x.length - newColsLength, newColsLength);
          x.splice(updateBoundsAtIndexCol-newColsLength+1, 0, ...newElements);
        })
      }
      else if(updateBoundsAtIndexCol && newColsLength < 0){
        newRows = oldRows;
        newRows.forEach(x => {
          x.splice(updateBoundsAtIndexCol+1, Math.abs(newColsLength));
        })
      }
      
      const newRowsLength = newRows.length - oldRows.length;
      if(updateBoundsAtIndexRow && newRowsLength > 0){
        const newElements = newRows.splice(newRows.length - newRowsLength, newRowsLength);
        newRows.splice(updateBoundsAtIndexRow-newRowsLength+1, 0, ...newElements);
      }
      else if(updateBoundsAtIndexRow && newRowsLength < 0){
        newRows = oldRows;
        newRows.splice(updateBoundsAtIndexRow+1, Math.abs(newRowsLength));
      }



      data.value = newRows;
      data.n = n;
      data.m = m;
      this.setState({ data }, () => {
        if (this.props.onUpdate) {
          this.props.onUpdate();
        }
        resolve();
      });
    });

  }

  onNChange(ev) {
    let n = !ev.target.value ? 0 : Number.parseFloat(ev.target.value);
    this.changeN(n);
  }

  async changeN(n) {
    if (Number.isNaN(n) || n < 0) {
      n = 0;
    }
    let m = this.state.data.m;
    if (this.state.data.isSquare) {
      m = n;
    } else {
      const bounds = this.state.data.bounds || {};
      if (bounds.minN) {
        n = Math.max(n, bounds.minN);
      }
      if (bounds.maxN) {
        n = Math.min(n, bounds.maxN);
      }
    }
    await this.updateBounds(m, n);
  }

  onMChange(ev) {
    let m = !ev.target.value ? 0 : Number.parseFloat(ev.target.value);
    this.changeM(m);
  }

  async changeM(m) {
    if (Number.isNaN(m) || m < 0) {
      m = 0;
    }
    let n = this.state.data.n;
    if (this.state.data.isSquare) {
      n = m;
    } else {
      const bounds = this.state.data.bounds || {};
      if (bounds.minM) {
        m = Math.max(m, bounds.minM);
      }
      if (bounds.maxM) {
        m = Math.min(m, bounds.maxM);
      }
    }
    await this.updateBounds(m, n);
  }

  isMatrixInputDisabled(m, n) {
    if (this.state.data.disabled) {
      const scope = Object.assign({}, { n, m });
      const parser = this.getMathParser(scope);
      return parser.evaluate(this.state.data.disabled);
    }
    return false;
  }

  async randomizeMatrix() {
    const expr = this.state.data.randomize;
    const p = this.getMathParser();
    const newMatrix = p.evaluate(expr);
    const promises = [
      this.changeN(newMatrix[0].length),
      this.changeM(newMatrix.length)
    ];
    await Promise.all(promises);
    const data = this.state.data;
    data.value = newMatrix;
    this.setState({ data });
  }

  async randomizeByValues() {
    const promises = [];
    const rndNRules = this.state.data.randomizeN;
    if (rndNRules) {
      const p = this.changeN(this.generateRandomNumber(rndNRules[0], rndNRules[1]));
      promises.push(p);
    }
    const rndMRules = this.state.data.randomizeM;
    if (rndMRules) {
      const p = this.changeM(this.generateRandomNumber(rndMRules[0], rndMRules[1]));
      promises.push(p);
    }
    await Promise.all(promises);
    this.randomizeMatrixValues();
  }

  async randomize() {
    if (this.state.data.randomize) {
      return this.randomizeMatrix();
    }
    return this.randomizeByValues();
  }

  processValue(m, n, val) {
    const expr =  this.state.data.processValue;
    if (!expr) {
      return val;
    }
    const mat = this.state.data.value;
    const p = this.getMathParser({n, m, val, mat});
    const newValue = p.evaluate(expr);
    this.updateValue(m, n, newValue);
    return newValue;
  }

  renderCell(m, n, val) {
    return (<input value={this.processValue(m, n, val)} className={this.validateCell(m, n) ? '' : styles.error} onChange={this.onValueChange.bind(this, m, n)} disabled={this.isMatrixInputDisabled(m, n)}></input>);
  }

  checkHTML(html) {
    var doc = document.createElement('div');
    doc.innerHTML = html;
    return ( doc.innerHTML === html );
  }

  renderColumnHeader(col) {
    const headers = this.state.data.headers;
    if (!headers) {
      return;
    }
    const expr = headers.col;
    if (!expr) {
      return;
    }
    const scope = Object.assign({}, { col });
    const parser = this.getMathParser(scope);
    const content = parser.evaluate(expr);
    if(this.checkHTML(content)){
      return ReactHtmlParser(content);
    }
    return content;
  }

  renderRowHeader(row) {
    const headers = this.state.data.headers;
    if (!headers) {
      return;
    }
    const expr = headers.row;
    if (!expr) {
      return;
    }
    const scope = Object.assign({}, { row });
    const parser = this.getMathParser(scope);
    const content = parser.evaluate(expr);
    if(this.checkHTML(content)){
      return ReactHtmlParser(content);
    }
    return content;
  }

  getNumRows() {
    const expr = this.state.data.mExpr;
    if (Number.isInteger(expr)) {
      return expr;
    }
    if (!expr) {
      const rows = this.state.data.value;
      return rows.length;
    }
    const parser = this.getMathParser();
    const r = parser.evaluate(expr);
    return r;
  }

  getNumCols() {
    const expr = this.state.data.nExpr;
    if (Number.isInteger(expr)) {
      return expr;
    }
    if (!expr) {
      const rows = this.state.data.value;
      return rows[0].length;
    }
    const parser = this.getMathParser();
    return parser.evaluate(expr);
  }

  componentDidUpdate() {
    const rows = this.state.data.value;
    const n = this.getNumCols();
    const m = this.getNumRows();
    if (rows.length !== m || rows[0].length !== n) {
      this.updateBounds(m, n);
    }
  }

  getColumnColor(i) {
    const headers = this.state.data.headers;
    if (!headers) {
      return;
    }
    const columnColors = headers.columnColors || [];
    const color = columnColors[i];
    if (!color) {
      return "default";
    }
    return color;
  }

  getRowColor(i) {
    const headers = this.state.data.headers;
    if (!headers) {
      return;
    }
    const rowColors = headers.rowColors || [];
    const color = rowColors[i];
    if (!color) {
      return "default";
    }
    return color;
  }

  renderInput() {
    const rows = this.state.data.value || [[]];
    const dynamic = this.state.data.dynamic;
    const n = this.state.data.n;
    const m = this.state.data.m;
    const labels = this.state.data.labels || {};
    const nLabel = labels.n ?? "N";
    const mLabel = labels.m ?? "M";
    const matrixLabel = labels.matrix ?? "";
    let dimensionsInput = [];
    if (dynamic) {
      dimensionsInput = (<div className={styles.dimInput}>
        {dynamic.includes("N") ? <div><div style={{minHeight: "5px"}}>{nLabel}</div> <input type="number" value={n} onChange={this.onNChange.bind(this)}></input></div> : <></>}
        {dynamic.includes("M") ? <div><div style={{minHeight: "5px"}}>{mLabel}</div> <input type="number" value={m} onChange={this.onMChange.bind(this)}></input></div> : <></>}
      </div>
      );
    }

    return (
      <div className={styles.myMatrixInput}>
        {dimensionsInput}
        <div>{matrixLabel}</div>
        <table>
          <thead>
            <tr>
              <th></th>
              {rows[0].map((col, i) =>
                <th key={i} className={styles.columnHeader} style={{color: this.getColumnColor(i)}}>{this.renderColumnHeader(i)}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, m) => (
              <tr key={m}>
                <th key={`h${m}`} style={{color: this.getRowColor(m)}} className={styles.rowHeader}>{this.renderRowHeader(m)}</th>
                {row.map((val, n) => (
                  <td key={n}>
                    {this.renderCell(m, n, val)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

