import MyGanttRenderer from 'core/renderers/MyGanttRenderer';
import { Tracer } from 'core/tracers';
import * as _ from 'lodash';


class MyGanttTracer extends Tracer {
  getRendererClass() {
    return MyGanttRenderer;
  }

  set(tasks = []) {
    this.data = this.data || {}
    this.data.tasks = tasks;
    super.set();
  }

  setAmountOfSteps(steps){
    this.data.steps = steps;
  }

  setLabels(rowLabel, colLabel){
    this.data.rowLabel = rowLabel;
    this.data.colLabel = colLabel;
  }

  patch(taskIds) {
    const ids = this.data.highlightedTasks || [];
    ids.push(...taskIds);
    this.data.highlightedTasks = ids;
  }

  depatch(taskIds) {
    const ids = this.data.highlightedTasks || [];
    this.data.highlightedTasks = _(ids).without(...taskIds).value();
  }
  
}

export default MyGanttTracer;
