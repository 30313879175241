import React from 'react';
import ReactDOM from 'react-dom';
import { combineReducers, createStore } from 'redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { routerReducer } from 'react-router-redux';
import App from 'components/App';
import Introduction from 'components/Indroduction'
import * as reducers from 'reducers';
import './stylesheet.scss';


if (process.env.REACT_APP_USE_APP_TRACKING === 'true') {
  const script = document.createElement('script');
  script.src = '/matomo-tracking.js';
  script.async = true;
  document.getElementById('scripts').appendChild(script);
}


const store = createStore(combineReducers({ ...reducers, routing: routerReducer }));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/:categoryKey/:algorithmKey" component={App} />
        <Route path="/ueber" component={Introduction} />
        <Redirect exact from="/" to="/Allgemeines/Startseite" />
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>
  </Provider>, document.getElementById('root'));

