import { Tracer } from 'core/tracers';
import { getCurrentPlayer } from 'components/Player';

class MyPseudoCodeTracer extends Tracer {

  constructor(){
    super();
    this.highlightedLines = [];

    this.removeEmptyLines();
  }

  removeEmptyLines(){
    const player = getCurrentPlayer();
    if(player.props.current && player.props.current.files && player.props.current.files.length >= 2){
      for(let file of player.props.current.files){
        if(file.name.endsWith("_pseudo.md")){
          const index = player.props.current.files.indexOf(file);
          player.props.current.files[index].content = player.props.current.files[index].content.replaceAll("\n\n", "\n");
        }
      }
    }
  }

  highlightLine(line) {
    if(!this.highlightedLines.includes(line)){
      this.highlightedLines.push(line);
    }
    this.updateHighlightedLines();
  }

  highlightLines(lines) {
    for(let line of lines){
      if(!this.highlightedLines.includes(line)){
        this.highlightedLines.push(line);
      }
    }
    this.updateHighlightedLines();
  }

  dehighlightLine(line) {
    const index = this.highlightedLines.indexOf(line);
    if(index !== -1){
      this.highlightedLines.splice(index, 1);
    }
    this.updateHighlightedLines();
  }

  dehighlightLines(lines) {
    for(let line of lines){
      const index = this.highlightedLines.indexOf(line);
      if(index !== -1){
        this.highlightedLines.splice(index, 1);
      }
    }
    this.updateHighlightedLines();
  }

  updateHighlightedLines(){
    const player = getCurrentPlayer();
    if(player.props.current && player.props.current.files && player.props.current.files.length >= 2){
      for(let file of player.props.current.files){
        if(file.name.endsWith("_pseudo.md")){
          const newPseudo = [];
          const split = file.content.split("\n");
          for(let line = 0; line < split.length; line++){  
            let content = split[line];
            content = content.replace(/<\/?mark>/g, '');
            if(this.highlightedLines.includes(line)){
              content = content.replace(/<\/?mark>/g, '');
              const match = content.match(/^(\s*#+\s*|\s*-\s*|\s*\d+\.\s*)(.*)$/);
              if (match) {
                const prefix = match[1];
                const text = match[2];
                newPseudo.push(`${prefix}<mark>${text}</mark>`);
              } 
              else {
                newPseudo.push(`<mark>${content}</mark>`);
              }
            }
            else {
              newPseudo.push(content);
            }
          }
          const index = player.props.current.files.indexOf(file);
          player.props.current.files[index].content = newPseudo.join("\n");
        }
      }
    }
  }
}

export default MyPseudoCodeTracer;
