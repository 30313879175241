import React, { Component } from 'react';
import styles from './CustomDropdown.module.scss';
import faChevronUp from '@fortawesome/fontawesome-free-solid/faChevronUp';
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown';
import faClock from '@fortawesome/fontawesome-free-regular/faClock';
import Button from 'components/Button';


class CustomDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedOption: props.selectedOption || props.options[0]
    };
    this.handleOptionClick = this.handleOptionClick.bind(this);
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleOptionClick = (option) => {
    this.setState({
      selectedOption: option,
      isOpen: false
    });
    this.props.onSelect(option);
  };

  render() {
    const { options } = this.props;
    const { isOpen, selectedOption } = this.state;

    return (
      <div className={styles.custom_dropdown}>
        <div className={styles.selected_option} onClick={this.toggleDropdown}>
          <Button className={styles.icon} icon={faClock}></Button>
          <p className={styles.responsiveText}>Speed</p>
          <Button className={styles.icon} icon={isOpen ? faChevronUp : faChevronDown}></Button>
        </div>
        {isOpen && (
          <div className={styles.options}>
            {options.map((option) => (
              <div
                key={option}
                className={`${styles.option} ${option === selectedOption ? styles.selected : ''}`}
                onClick={() => this.handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default CustomDropdown;
