/* eslint-disable import/no-anonymous-default-export */
import React, { createRef } from 'react';
import { Renderer } from 'core/renderers';
import styles from './MyPlotterRenderer.module.scss';
import * as jsxgraph from 'jsxgraph';
import 'jsxgraph/distrib/jsxgraph.css';


let idCounter = 0;

export default class extends Renderer {
    constructor(props) {
        super(props);
        this.board = null;
        this.objectsOnBoard = []
        this.toggleZoom(false);
        this.togglePan(false);
        this.id = ++idCounter;
        this.plotData = null;
        this.opts = props.data.opts || {};
    }
    clear() {
        for (const fg of this.objectsOnBoard) {
            this.board.removeObject(fg);
        }
        this.objectsOnBoard = [];
    }

    addFunctionGraph(fgExpression, obj) {
        const color = obj.color || "blue";
        const fg = this.board.create('functiongraph', [fgExpression], {strokeWidth: 2, strokeColor: color});
        this.objectsOnBoard.push(fg);
        if (obj.showIntersectionPoints === false) {
            return;
        }
        // Berechne Schnittpunkte mit den Koordinatenachsen
        const xAxisIntersection = this.board.create('intersection', [fg, this.board.defaultAxes.x, 0], {name: '', size: 5, fillColor: 'red', strokeColor: 'red'});
        this.objectsOnBoard.push(xAxisIntersection);

        const yAxisIntersection = this.board.create('intersection', [fg, this.board.defaultAxes.y, 0], {name: '', size: 5, fillColor: 'red', strokeColor: 'red'});
        this.objectsOnBoard.push(yAxisIntersection);
        // Berechne Schnittpunkte mit allen anderen bisherigen Funktionen und markiere diese rot
        this.objectsOnBoard.forEach(otherFg => {
            if (otherFg !== fg) {
                // Erzeuge Schnittpunkte als rot gefüllte Kreise
                const intersection = this.board.create('intersection', [fg, otherFg, 0], {name: '', size: 4, fillColor: 'red', strokeColor: 'red'});
                this.objectsOnBoard.push(intersection);
                const intersection2 = this.board.create('intersection', [fg, otherFg, 1], {name: '', size: 4, fillColor: 'red', strokeColor: 'red'});
                this.objectsOnBoard.push(intersection2);
            }
        });
    }

    addPointList(pointList, obj) {
        const color = obj.color || "blue";
        const strokeWidth = obj.strokeWidth || 2;
        const xs = pointList.map(p => p.x);
        const ys = pointList.map(p => p.y);
        const c = this.board.create('curve',[xs, ys], {strokeWidth, strokeColor: color});
        this.objectsOnBoard.push(c);
        for(let i = 0; i<xs.length; ++i) {
            const p = this.board.create('point', [xs[i], ys[i]], {strokeColor: "none", fillColor: color, withLabel: false});
            this.objectsOnBoard.push(p);
        }
    }

    componentDidMount() {
        super.componentDidMount();
        const bounds = this.opts.bounds || [-60, 30, 60, -30];
        this.board = jsxgraph.JSXGraph.initBoard(`plt-${this.id}`, { boundingbox: bounds, axis: true, showCopyright: false, showZoom: true});
        this.board.options.grid.color = "red";
        this.board.update();
    }
    addFunctions(data) {
        for (const exprObj of data) {
            if (exprObj.f) {
                this.addFunctionGraph(exprObj.f, exprObj);
            }
            if (exprObj.pointList) {
                this.addPointList(exprObj.pointList, exprObj);
            }
        }
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        this.updateIfNecessary();
    }

    updateIfNecessary() {
        if (!this.props.data) {
            return;
        }
        const inData = this.props.data.data;
        if (inData === this.plotData) {
            return;
        }
        setTimeout(() => {
            this.clear();
            if (this.board) {
                this.addFunctions(inData || []);
            }
        });
        this.plotData = inData;
    }

    renderData() {
        return (
            <div id={`plt-${this.id}`} className={styles.JSXGraph} style={{
                width: "100%",
                height: "100%",
                marginLeft: -this.centerX * 2,
                marginTop: -this.centerY * 2,
                transform: `scale(${this.zoom})`
            }}></div>
        );
    }
}
