import { Tracer } from 'core/tracers';
import { distance } from 'common/util';
import { GraphRenderer } from 'core/renderers';
import _ from 'lodash';

class GraphTracer extends Tracer {
  getRendererClass() {
    return GraphRenderer;
  }

  init(test) {
    super.init();
    this.dimensions = {
      baseWidth: 320,
      baseHeight: 320,
      padding: 32,
      nodeRadius: 12,
      arrowGap: 4,
      nodeWeightGap: 4,
      edgeWeightGap: 4,
    };
    this.isDirected = true;
    this.isWeighted = false;
    this.isFlowed = false;
    this.isLastNodeT = false;
    this.graphNodes = [];
    this.callLayout = { method: this.layoutCircle, args: [] };
    this.logTracer = null;
    this.dragEnabled = true;
    this.allowParallelEdges = false;
  }

  myAllowParallelEdges(val) {
    this.allowParallelEdges = val;
  }

  set(array2d = []) {
    this.nodes = [];
    this.edges = [];
    for (let i = 0; i < array2d.length; i++) {
      this.addNode(i);
      for (let j = 0; j < array2d.length; j++) {
        const value = array2d[i][j];
        if (value && Array.isArray(value) && value.length === 2 && value[0] && value[0] !== '' && value[1] !== '') {
          this.addEdge(i, j, this.isWeighted ? value[0] : null, this.isFlowed && value[1] !== 0 ? value[1] : null);
        }
        else if(value && !Array.isArray(value)){
          this.addEdge(i, j, this.isWeighted ? value : null);
        }
      }
    }
    this.layout();
    super.set();
  }

  directed(isDirected = true) {
    this.isDirected = isDirected;
  }

  weighted(isWeighted = true) {
    this.isWeighted = isWeighted;
  }

  flowed(isFlowed = true) {
    this.isFlowed = isFlowed;
  }

  mySetNodeLabels(labels) {
    this.graphNodeLabels = labels;
    super.set();
  }

  myViewOptions(options) {
    this.viewOptions = options;
  }

  myAddAuxiliaryGraphics(auxElements) {
    this.auxElements = auxElements;
  }

  addNode(id, weight = null, flow = null, x = 0, y = 0, visitedCount = 0, selectedCount = 0) {
    if (this.findNode(id)) return;
    this.nodes.push({ id, weight, flow, x, y, visitedCount, selectedCount });
    this.layout();
  }

  updateNode(id, weight, flow, x, y, visitedCount, selectedCount) {
    const node = this.findNode(id);
    const update = { weight, flow, x, y, visitedCount, selectedCount };
    Object.keys(update).forEach(key => {
      if (update[key] === undefined) delete update[key];
    });
    Object.assign(node, update);
  }

  moveNode(id, v1, v2) {
    const node = this.findNode(id);
    const rect = this.getRect();

    node.x = (rect.left + rect.right) / 2;
    node.y = (rect.top + rect.bottom) / 2;
    return;
  }

  removeNode(id) {
    const node = this.findNode(id);
    if (!node) return;
    const index = this.nodes.indexOf(node);
    this.nodes.splice(index, 1);
    this.layout();
  }

  addEdge(source, target, weight = null, flow = null, visitedCount = 0, selectedCount = 0) {
    if (this.allowParallelEdges === false) {
      if (this.findEdge(source, target)) return;
      const newEdge = { source, target, weight, flow, visitedCount, selectedCount, parallelNr: 0 };
      this.edges.push(newEdge);
      this.layout();
      return newEdge;
    }
    if (this.isDirected || this.isFlowed) {
      throw new Error("parallel edges not supported on a directed or flowed graph")
    }
    const edges = this.findEdges(source, target);
    const newEdge = { source, target, weight, flow, visitedCount, selectedCount, parallelNr: edges.length };
    this.edges.push(newEdge);
    this.layout();
    return newEdge;
  }

  myAddEdge(source, target, edgeId, weight = null, flow = null) {
    const newEdge = this.addEdge(source, target, weight, flow);
    newEdge.customEdgeId = edgeId;
  }

  updateEdge(source, target, weight, flow, visitedCount, selectedCount) {
    const edge = this.findEdge(source, target);
    if (!edge) {
      console.error(`Kante von ${source} nach ${target} nicht gefunden.`);
      return;
    }
    const update = { weight, flow, visitedCount, selectedCount };
    Object.keys(update).forEach(key => {
      if (update[key] === undefined) delete update[key];
    });
    Object.assign(edge, update);
  }

  removeEdge(source, target) {
    const edge = this.findEdge(source, target);
    if (!edge) return;
    const index = this.edges.indexOf(edge);
    this.edges.splice(index, 1);
    this.layout();
  }

  findNode(id) {
    const result =  this.nodes.find(node => node.id === id);
    return result;
  }

  findEdge(source, target, isDirected = this.isDirected) {
    if (isDirected) {
      return this.edges.find(edge => edge.source === source && edge.target === target);
    } else {
      return this.edges.find(edge =>
        (edge.source === source && edge.target === target) ||
        (edge.source === target && edge.target === source));
    }
  }

  findEdgeById(edgeId) {
    return this.edges.find(edge => edge.customEdgeId === edgeId);
  }

  findEdges(source, target, isDirected = this.isDirected) {
      return this.edges.filter(edge =>
        (edge.source === source && edge.target === target) ||
        (edge.source === target && edge.target === source));
  }

  findLinkedEdges(source, isDirected = this.isDirected) {
    if (isDirected) {
      return this.edges.filter(edge => edge.source === source);
    } else {
      return this.edges.filter(edge => edge.source === source || edge.target === source);
    }
  }

  findLinkedNodeIds(source, isDirected = this.isDirected) {
    const edges = this.findLinkedEdges(source, isDirected);
    return edges.map(edge => edge.source === source ? edge.target : edge.source);
  }

  findLinkedNodes(source, isDirected = this.isDirected) {
    const ids = this.findLinkedNodeIds(source, isDirected);
    return ids.map(id => this.findNode(id));
  }

  getRect() {
    const { baseWidth, baseHeight, padding } = this.dimensions;
    const left = -baseWidth / 2 + padding;
    const top = -baseHeight / 2 + padding;
    const right = baseWidth / 2 - padding;
    const bottom = baseHeight / 2 - padding;
    const width = right - left;
    const height = bottom - top;
    return { left, top, right, bottom, width, height };
  }

  layout() {
    const { method, args } = this.callLayout;
    method.apply(this, args);
  }

  layoutCircle() {
    this.callLayout = { method: this.layoutCircle, args: arguments };
    const rect = this.getRect();
    const unitAngle = 2 * Math.PI / this.nodes.length;
    let angle = -Math.PI / 2;
    for (const node of this.nodes) {
      const x = Math.cos(angle) * rect.width / 2;
      const y = Math.sin(angle) * rect.height / 2;
      node.x = x;
      node.y = y;
      angle += unitAngle;
    }
  }

  myTableLayout(numCols) {
    this.callLayout = { method: this.myTableLayout, args: arguments };
    const rect = this.getRect();
    const xGap = rect.width / numCols;
    const numRows = Math.ceil(this.nodes.length / numCols);
    const yGap = rect.height / numRows;
    let col = 0;
    let row = 0;
    for (const node of this.nodes) {
      node.x = rect.left + col * xGap;
      node.y = rect.top + row * yGap;
      ++col;
      if (col >= numCols) {
        col = 0;
        ++row;
      }
    }
  }

  layoutTree(root = 0, sorted = false) {
    this.callLayout = { method: this.layoutTree, args: arguments };
    const rect = this.getRect();

    if (this.nodes.length === 1) {
      const [node] = this.nodes;
      node.x = (rect.left + rect.right) / 2;
      node.y = (rect.top + rect.bottom) / 2;
      return;
    }

    let maxDepth = 0;
    const leafCounts = {};
    let marked = {};
    const recursiveAnalyze = (id, depth) => {
      marked[id] = true;
      leafCounts[id] = 0;
      if (maxDepth < depth) maxDepth = depth;
      const linkedNodeIds = this.findLinkedNodeIds(id, false);
      for (const linkedNodeId of linkedNodeIds) {
        if (marked[linkedNodeId]) continue;
        leafCounts[id] += recursiveAnalyze(linkedNodeId, depth + 1);
      }
      if (leafCounts[id] === 0) leafCounts[id] = 1;
      return leafCounts[id];
    };
    recursiveAnalyze(root, 0);

    const hGap = rect.width / leafCounts[root];
    const vGap = rect.height / maxDepth;
    marked = {};
    const recursivePosition = (node, h, v) => {
      marked[node.id] = true;
      node.x = rect.left + (h + leafCounts[node.id] / 2) * hGap;
      node.y = rect.top + v * vGap;
      const linkedNodes = this.findLinkedNodes(node.id, false);
      if (sorted) linkedNodes.sort((a, b) => a.id - b.id);
      for (const linkedNode of linkedNodes) {
        if (!linkedNode) {
          continue;
        }
        if (marked[linkedNode.id]) continue;
        recursivePosition(linkedNode, h, v + 1);
        h += leafCounts[linkedNode.id];
      }
    };
    const rootNode = this.findNode(root);
    if (rootNode === undefined) {
      return;
    }
    recursivePosition(rootNode, 0, 0);
  }

  layoutRandom(offsetRect = {left:0, top:0, width:0, height:0}) {
    this.callLayout = { method: this.layoutRandom, args: arguments };
    const rect = this.getRect();
    const placedNodes = [];

    for (const node of this.nodes) {
      do {
        node.x = (rect.left + offsetRect.left) + Math.random() * (rect.width + offsetRect.width);
        node.y = (rect.top + offsetRect.top) + Math.random() * (rect.height + offsetRect.height);
      } while (placedNodes.find(placedNode => distance(node, placedNode) <= 50));
      placedNodes.push(node);
    }
  }

  layoutSpecfic(coordinates) {
    this.callLayout = { method: this.layoutSpecfic, args: arguments };
    const rect = this.getRect();
    const maxCoordX = _(coordinates).map(coordinate => coordinate[0]).max();
    const maxCoordY = _(coordinates).map(coordinate => coordinate[1]).max();
    let index = 0;
    for (const node of this.nodes) {
      const coordinate = coordinates[index++]
      node.x = rect.left + (coordinate[0] * rect.width / maxCoordX);
      node.y = rect.bottom - (coordinate[1] * rect.height / maxCoordY);
    }
  }

  setDragEnabled(val) {
    this.dragEnabled = val;
  }

  visit(target, source, weight, styling) {
    this.visitOrLeave(true, target, source, weight, styling);
  }

  myVisitEdge(edgeId, styling) {
    const edge = this.findEdgeById(edgeId);
    if (!edge) {
      return;
    }
    edge.visitedCount = 1;
    edge.styling = styling || {};
  }

  myLeaveEdge(edgeId) {
    const edge = this.findEdgeById(edgeId);
    if (!edge) {
      return;
    }
    edge.visitedCount = 0;
  }

  leave(target, source, weight) {
    this.visitOrLeave(false, target, source, weight);
  }

  visitOrLeave(visit, target, source = null, weight, styling) {
    styling = styling || {};
    const edge = this.findEdge(source, target);
    if (edge) {
      edge.visitedCount += visit ? 1 : -1;
      edge.styling = styling;
    }
    const node = this.findNode(target);
    node.styling = styling;
    if (weight !== undefined) node.weight = weight;
    node.visitedCount += visit ? 1 : -1;
    if (this.logTracer) {
      this.logTracer.println(visit ? (source || '') + ' -> ' + target : (source || '') + ' <- ' + target);
    }
  }

  selectFlow(target, source = null) {
    const edge = this.findEdge(target, source);
    edge.highlightFlow = true;
  }

  deselectFlow(target, source = null) {
    const edge = this.findEdge(target, source);
    edge.highlightFlow = false;
  }

  select(target, source, showLogs = true, selectEdge = false) {
    this.selectOrDeselect(true, target, source, showLogs, selectEdge);
  }

  deselect(target, source, showLogs = true, selectEdge = false) {
    this.selectOrDeselect(false, target, source, showLogs, selectEdge);
  }

  selectOrDeselect(select, target, source = null, showLogs = true, selectEdge = false) {
    const edge = this.findEdge(source, target);
    if (edge) edge.selectedCount += select ? 1 : -1;
    
    if(!selectEdge) {
      const node = this.findNode(target);
      node.selectedCount += select ? 1 : -1;
    }
    
    if (this.logTracer && showLogs) {
      this.logTracer.println(select ? (source || '') + ' => ' + target : (source || '') + ' <= ' + target);
    }
  }

  log(key) {
    this.logTracer = key ? this.getObject(key) : null;
  }

}

export default GraphTracer;
