import React, { createRef } from 'react';
import { Renderer } from 'core/renderers';
import styles from './MyValueInputRenderer.module.scss';
import { getCurrentPlayer } from 'components/Player';
import { MyCurrentJsData } from 'apis';
import { MyNumberInput } from './inputs/numberInput';
import { MyMatrixInput } from './inputs/matrixInput';
import * as _ from 'lodash';
import { MyDropdownInput } from './inputs/dropdownInput';

class MyValueInputRenderer extends Renderer {
  constructor(props) {
    super(props);
    this.togglePan(true);
    this.toggleZoom(true);
    this.state = {
      variables: this.props.data.data.variables,
      options: this.props.data.data.options,
      isValid: true,
      isUnfolded: true
    };
    const variables = this.state.variables || {};
    this.inputRendererRefs = _(variables).mapValues(x => createRef()).value();
  }

  reBuild() {
    const player = getCurrentPlayer();
    const file = player.getJSFile();
    for (const varName in this.state.variables) {
      const obj = this.state.variables[varName];
      MyCurrentJsData[varName] = obj.value;
    }
    player.build(file);
  }

  onOk(ev) {
    ev.preventDefault();
    this.setDefaultValuesForAll();
    if (!this.validate()) {
      alert("Die Eingabe ist ungültig.")
      return;
    }
    setTimeout(this.reBuild.bind(this), 100);
  }

  setDefaultValuesForAll(){
    for(const varIdentifier in this.inputRendererRefs) {
      const ref = this.inputRendererRefs[varIdentifier];
      if(ref.current.setDefaultValuesForAll){
        ref.current.setDefaultValuesForAll();
      }
    }
  }

  onRandom(ev) {
    ev.preventDefault();
    for(const varIdentifier in this.inputRendererRefs) {
      const ref = this.inputRendererRefs[varIdentifier];
      ref.current.randomize();
    }
    setTimeout(() => this.validate(), 1);
  }

  validate() {
    let result = true;
    for(const varIdentifier in this.inputRendererRefs) {
      const ref = this.inputRendererRefs[varIdentifier];
      result &= ref.current.validate();
    }
    this.setState({isValid: result});
    return result;
  }

  onUpdate() {
    this.validate();
    this.forceUpdate();
  }

  renderData() {
    const variables = [];
    for (const varIdentifier in this.state.variables) {
      const data = this.state.variables[varIdentifier];
      const name = data.name;
      if (data.type === 'numberInput') {
        variables.push(
          <div className={styles.inputEntry} key={varIdentifier}>
            <span>{name}</span>
            <MyNumberInput ref={this.inputRendererRefs[varIdentifier]} onUpdate={this.onUpdate.bind(this)} data={data} variables={this.state.variables}></MyNumberInput>
          </div>
        )
      }
      if (data.type === 'dropdownInput') {
        variables.push(
          <div className={styles.inputEntry} key={varIdentifier}>
            <span>{name}</span>
            <MyDropdownInput ref={this.inputRendererRefs[varIdentifier]} onUpdate={this.onUpdate.bind(this)} data={data} onValueChange={this.validate.bind(this)} variables={this.state.variables}></MyDropdownInput>
          </div>
        )
      }
      if (data.type === 'matrixInput') {
        variables.push(
          <div className={styles.inputEntry} key={varIdentifier}>
            <span>{name}</span>
            <MyMatrixInput ref={this.inputRendererRefs[varIdentifier]} onUpdate={this.onUpdate.bind(this)} data={data} onValueChange={this.validate.bind(this)} variables={this.state.variables}></MyMatrixInput>
          </div>
        )
      }
    }
    
    this.moveX = -this.centerX;
    this.moveY = -this.centerY;

    let displayRandomizeButton = false;

    for (var prop in this.state.variables){
      if(this.state.variables[prop]["randomizeValues"] || this.state.variables[prop]["randomize"]){
        displayRandomizeButton = true;
      }
    }
    
    return (
      <div style={{
        transform: `translate(${this.moveX}px, ${this.moveY}px) scale(${this.zoom})`
      }}>
        <div className={styles.myInputRenderer}>
          <form>
            {variables}
            <p className={this.state.isValid ? styles.invisible : styles.errorText}>Die Eingabe ist ungültig!</p>
            <div className={styles.navigation}>
              <button onClick={this.onOk.bind(this)}>OK</button>
              {displayRandomizeButton &&
                <button onClick={this.onRandom.bind(this)}>Zufällige Generierung</button>
              }
            </div>
          </form>
          <br></br>
        </div>
      </div>

    );
  }
}

export default MyValueInputRenderer;

