import React from 'react';
import { Array1DRenderer, Renderer } from 'core/renderers';
import styles from './Array2DRenderer.module.scss';
import { classes } from 'common/util';
import ReactHtmlParser from 'react-html-parser'; 

class Array2DRenderer extends Renderer {
  constructor(props) {
    super(props);


    this.togglePan(true);
    this.toggleZoom(true);
  }

  getLabel(value){
    return value;
  }

  checkHTML(html) {
    var doc = document.createElement('div');
    doc.innerHTML = html;
    return ( doc.innerHTML === html );
  }

  renderData() {
    const { data } = this.props.data;

    const isArray1D = this instanceof Array1DRenderer;
    let longestRow = data.reduce((longestRow, row) => longestRow.length < row.length ? row : longestRow, []);
    const colHeader = (data.header || {}).columns || [];
    const rowHeader = (data.header || {}).rows || [];

    this.moveX = -this.centerX;
    this.moveY = -this.centerY;

    return (
      <table className={styles.array_2d}
             style={{ transform: `translate(${this.moveX}px, ${this.moveY}px) scale(${this.zoom})` }}>
        <tbody>
        <tr className={styles.row}>
          {
            !isArray1D &&
            <td className={classes(styles.col, styles.colHeader, styles.index)}>{data.title}</td>
          }
          {
            longestRow.map((_, i) => (
              <td className={classes(styles.col, styles.index)} key={i}>
                <span className={styles.value}>{this.checkHTML(colHeader[i]) ? ReactHtmlParser(colHeader[i]) : this.getLabel(colHeader[i] || i)}</span>
              </td>
            ))
          }
        </tr>
        {
          data.map((row, i) => (
            <tr className={styles.row} key={i}>
              {
                !isArray1D &&
                <td className={classes(styles.col, styles.colHeader, styles.index)}>
                  <span className={styles.value}>{this.checkHTML(rowHeader[i]) ? ReactHtmlParser(rowHeader[i]) : rowHeader[i] || i}</span>
                </td>
              }
              {
                row.map((col, j) => (
                  <td className={classes(styles.col, col.selected && styles.selected, col.patched && styles.patched)}
                      key={j}>
                    <span className={styles.value}>{(() => {
                      const val = this.toString(data.toFixed && typeof(col.value) === 'number' ? col.value.toFixed(data.toFixed) : col.value);
                      return this.checkHTML(val) ? ReactHtmlParser(val) : val;
                    })()}</span>
                  </td>
                ))
              }
            </tr>
          ))
        }
        </tbody>
      </table>
    );
  }
}

export default Array2DRenderer;

