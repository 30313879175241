import MyValueInputRenderer from 'core/renderers/MyValueInputRenderer';
import { Tracer } from 'core/tracers';

class MyValueInputTracer extends Tracer {
  getRendererClass() {
    return MyValueInputRenderer;
  }

  set(variables) {
    this.data = variables;
    super.set();
  }
}

export default MyValueInputTracer;
